<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import Password from "@/components/widgets/input-password";

export default {
  locales: {
    pt: {
      'Security Password': 'Senha de Segurança',
      'Recover security password': 'Recuperar senha de segurança',
      'Yes': 'Sim',
      'Close': 'Fechar',

      'Current password access': 'Senha atual de acesso',
      'Current Security Password': 'Senha de segurança atual',
      'New security password': 'Nova senha de segurança',
      'Confirm security password': 'Confirmar nova senha de segurança',
      'Save': 'Salvar',

      'The current password entered is invalid.': 'A senha atual de acesso informada é inválida.',
      'The current security password entered is invalid.': 'A senha atual de segurança informada é inválida.',
      'Your security password has been changed successfully.': 'Sua senha de segurança foi alterada com sucesso.',

      'A code for creating a new password will be sent to your email. You have up to 24 hours to change your password. Do you want to recover your security password?': 'Um código para criação de uma nova senha será enviado ao seu e-mail. Você tem até 24 horas para alterar sua senha. Deseja recuperar sua senha de segurança?',
      'We have sent an email to retrieve your security password.': 'Enviamos um email para recuperar sua senha de segurança.',
      'There was an error sending the recovery email, please try again later.': 'Ocorreu um erro ao enviar o e-mail de recuperação. Tente novamente mais tarde.',
    },
    es: {
      'Security Password': 'Contraseña de Seguridad',
      'Recover security password': 'Recuperar contraseña de seguridad',
      'Yes': 'Si',
      'Close': 'Cerrar',

      'Current password access': 'Contraseña actual de accesso',
      'Current Security Password': 'Contraseña de seguridad actual',
      'New security password': 'Nueva contraseña de seguridad',
      'Confirm security password': 'Confirmar contraseña de seguridad',
      'Save': 'Salvar',

      'The current password entered is invalid.': 'La contraseña actual ingresada no es válida.',
      'The current security password entered is invalid.': 'La contraseña de seguridad actual ingresada no es válida.',
      'Your security password has been changed successfully.': 'Su contraseña de seguridad se ha cambiado correctamente.',

      'A code for creating a new password will be sent to your email. You have up to 24 hours to change your password. Do you want to recover your security password?': 'Use le enviará un código para crear una nueva contraseña a su correo electrónico. Tienes hasta 24 horas para cambiar tu contraseña. ¿Quieres recuperar tu contraseña de seguridad?',
      'We have sent an email to retrieve your security password.': 'Hemos enviado un correo electrónico para recuperar su contraseña de seguridad.',
      'There was an error sending the recovery email, please try again later.': 'Se produjo un error al enviar el correo electrónico de recuperación. Vuelve a intentarlo más tarde.',
    }
  },
  components: {
    Layout,
    Password
  },
  data() {
    return {
      token: null,

      alert: {
        password: { type: '', message: '' },
        recover: { type: '', message: '' },
        reset: { type: '', message: '' },
      },

      passwordSecurity: {
        status: true,
      },

      password: {
        loading: false,

        currentPassword: "",
        currentSecurity: "",
        newSecurity: "",
        confirmSecurity: ""
      },
    };
  },
  validations: {
    password: {
      currentPassword: { required, minLength: minLength(6) },
      currentSecurity: { minLength: minLength(6) },
      newSecurity: { required, minLength: minLength(6) },
      confirmSecurity: { required, minLength: minLength(6), sameAs: sameAs('newSecurity') },
    }
  },
  methods: {
    getPasswordSecurity() {
      api
        .get('password/security')
        .then(response => {
          if (response.data.status === 'registered') {
            this.passwordSecurity.status = true
          } else {
            this.passwordSecurity.status = false
          }
        });
    },
    passwordSubmit() {
      this.$v.password.$touch();
      if (this.$v.password.$invalid) {
        return;
      } else {
        const { currentPassword, newSecurity, confirmSecurity } = this.password;
        if (currentPassword && newSecurity && confirmSecurity) {
          this.password.loading = true

          api
            .post('password/security', {
              currentPassword: this.password.currentPassword,
              currentSecurity: this.password.currentSecurity,
              newSecurity: this.password.newSecurity,
              confirmSecurity: this.password.confirmSecurity
            })
            .then(response => {
              if (response.data.status == 'success') {
                this.password.currentPassword = ''
                this.password.currentSecurity = ''
                this.password.newSecurity = ''
                this.password.confirmSecurity = ''

                this.$v.password.$reset();

                this.passwordSecurity.status = true

                this.alert.password.type = 'alert-success'
                this.alert.password.message = response.data.message
              } else {
                this.alert.password.type = 'alert-danger'
                this.alert.password.message = response.data.message
              }

              this.password.loading = false
            });
        }
      }
    },
    recoverSecurity() {
      api
        .post('password/security/recover')
        .then(response => {
          if (response.data.status == 'success') {
            this.alert.recover.type = 'success';
            this.alert.recover.message = response.data.message;
          }
        });
    },
    resetSecurity() {
      api
        .get('password/security/recover/' + this.$route.query.token)
        .then(response => {
          if (response.data.status == 'success') {
            this.$router.push('/account/password/security');

            this.alert.reset.type = 'success';
            this.alert.reset.message = response.data.message;
          } else {
            this.alert.reset.type = 'danger';
            this.alert.reset.message = response.data.message;
          }
        })
    }
  },
  mounted() {
    this.getPasswordSecurity();
    if (this.$route.query.token) {
      this.resetSecurity();
    }
  }
}
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Security Password') }}</h3>
        <p></p>
      </div>
      <div>
        <b-button v-if="this.passwordSecurity.status" variant="default" @click="$bvModal.show('recoverSecurity')">{{ t('Recover security password') }}</b-button>
        <b-modal id="recoverSecurity" hide-footer>
          <template v-slot:modal-title>
            {{ t('Recover security password') }}
          </template>
          <b-form class="p-3" @submit.prevent="recoverSecurity">
            <div class="d-block text-center">
              <p>{{ t('A code for creating a new password will be sent to your email. You have up to 24 hours to change your password. Do you want to recover your security password?') }}</p>
            </div>
            <div class="d-flex flex-row">
              <b-button class="m-3" variant="default" type="submit" block @click="$bvModal.hide('recoverSecurity')">{{ t('Yes') }}</b-button>
              <b-button class="m-3" variant="danger" block @click="$bvModal.hide('recoverSecurity')">{{ t('Close') }}</b-button>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>

    <div v-if="alert.reset.message" :class="'card bg-' + alert.reset.type + ' text-white'">
      <div class="card-body">
        {{ t(alert.reset.message) }}
      </div>
    </div>
    <div v-else-if="alert.recover.message" :class="'card bg-' + alert.recover.type + ' text-white'">
      <div class="card-body">
        {{ t(alert.recover.message) }}
      </div>
    </div>
    <div v-else-if="!this.passwordSecurity.status" class="card bg-danger text-white">
      <div class="card-body">
        Você ainda não possui uma senha de segurança cadastrada, para cadastrar preencha o formulário abaixo.
      </div>
    </div>
    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body">
            <b-form class="p-3" @submit.prevent="passwordSubmit">
              <div v-if="alert.password.message" :class="'alert ' + alert.password.type">{{ t(alert.password.message) }}</div>
              <b-form-group id="current-bo" :label="t('Current password access')" label-for="current-bo">
                <Password v-model="password.currentPassword" :v="$v.password.currentPassword"></Password>
              </b-form-group>
              <b-form-group v-if="this.passwordSecurity.status" id="current-security" :label="t('Current Security Password')" label-for="current-security">
                <Password v-model="password.currentSecurity" :v="$v.password.currentSecurity"></Password>
              </b-form-group>
              <b-form-group id="newest-security" :label="t('New security password')" label-for="newest-security">
                <Password v-model="password.newSecurity" :v="$v.password.newSecurity"></Password>
              </b-form-group>
              <b-form-group id="confirm-security" :label="t('Confirm security password')" label-for="confirm-security">
                <Password v-model="password.confirmSecurity" :v="$v.password.confirmSecurity"></Password>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="password.loading || !this.password.currentPassword || !this.password.newSecurity || !this.password.confirmSecurity || this.password.newSecurity != this.password.confirmSecurity"
                  type="submit" variant="default">
                  {{ t('Save') }}
                  <b-spinner v-if="password.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>